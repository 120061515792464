import React, { Fragment } from 'react';
import classes from './SideDrawer.module.css';
import Backdrop from '../../UI/Backdrop/Backdrop';
import NavigationItems from '../NavigationItems/NavigationItems';
import NavigationItem from '../NavigationItems/NavigationItem/NavigationItem';
import { withRouter, matchPath } from 'react-router';

const SideDrawer = props => {
    let currentClasses = [classes.SideDrawer];
    currentClasses.push(props.show ? classes.Open : classes.Close);
    console.log(props)
    return (
        <Fragment>
            <Backdrop show={props.show} clicked={props.close} />
            <div
                className={currentClasses.join(' ')}
                onKeyDown={event => event.key === 'Escape' ? props.close() : null}>
                {props.title ? props.title : null}
                <nav>
                    <NavigationItems close={props.close}>
                        <NavigationItem path='/home'>
                            Home
                        </NavigationItem>
                        <NavigationItem path='/about'>
                            About
                        </NavigationItem>
                        <NavigationItem path='/portfolio' parent>
                            Portfolio
                        </NavigationItem>
                    </NavigationItems>
                </nav>
                {matchPath(props.location.pathname, '/portfolio') ?
                    <nav className={classes.DesktopOnly} style={{margin:'0 10px'}}>
                        <NavigationItems>
                            <NavigationItem path='/portfolio/intro'>
                                Introduction
                        </NavigationItem>
                            <NavigationItem path='/portfolio/ck'>
                                C.Knowledge
                        </NavigationItem>
                            <NavigationItem path='/portfolio/backend'>
                                Backend
                        </NavigationItem>
                            <NavigationItem path='/portfolio/frontend'>
                                Frontend
                        </NavigationItem>
                        </NavigationItems>
                    </nav> : null}           
            </div>
        </Fragment>
    );
}

export default withRouter(SideDrawer);
import React from 'react'
import classes from './NavigationItems.module.css'

const NavigationItems = props => (
    <ul className={classes.NavigationItems} onClick={() => { 
        if(props.close) {
            props.close()
        };
        window.scrollTo(0,0);
    }}>
        {props.children}
    </ul>
);

export default NavigationItems
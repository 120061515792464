import React, { Fragment } from 'react';
import classesBase from '../Pages.module.css';
import classes from './Home.module.css';
import codeLogo from '../../../assets/code.png';
import internetLogo from '../../../assets/internet.png';
import desktopLogo from '../../../assets/desktop.png';
import mobileLogo from '../../../assets/mobile.png';
import ciLogo from '../../../assets/ci.png';
import consoleLogo from '../../../assets/console.png';
import osLogo from '../../../assets/os.png';
import sqlLogo from '../../../assets/sql.png';
import nosqlLogo from '../../../assets/nosql.png';
import automatizationLogo from '../../../assets/automatization.png';
import architectureLogo from '../../../assets/architecture.png';
import methodologiesLogo from '../../../assets/methodologies.png';
import leadershipLogo from '../../../assets/leadership.png';
import infrastructureLogo from '../../../assets/infrastructure.png';
import cloudLogo from '../../../assets/cloud.png';

const Home = () => {
    return (
        <Fragment>
            <div className={classes.Information}>
                <div className={classes.InformationContent}>
                    <p className={classes.InformationName}>Miguel Velásquez</p>
                    <p>VP of Engineering/Developer/Lead/Mentor</p>
                    <div>
                        <a href='http://linkedin.com/in/miguelvelasquez10' target='_blank' rel='noreferrer' >
                            <button className={classes.Linkedin} />
                        </a>
                        <a href='https://github.com/mvelasquez10' target='_blank' rel='noreferrer' >
                            <button className={classes.Github} />
                        </a>
                    </div>
                </div>
            </div>
            <div className={classesBase.Content} style={{justifyContent: 'center'}}>                
                <div className={classes.Skill}>
                    <img src={codeLogo} alt='code' />
                    <h2>Languages</h2>
                    <p>
                        C#, Python, C, C++, Java, JavaScript, Golang, Dart
                     </p>
                </div>
                <div className={classes.Skill}>
                    <img src={internetLogo} alt='internet' />
                    <h2>Web</h2>
                    <p>
                        HTML, CSS, React, ASP.NET
                     </p>
                </div>
                <div className={classes.Skill}>
                    <img src={desktopLogo} alt='desktop' />
                    <h2>Desktop</h2>
                    <p>
                        WinForms, WPF, GTK, Gnome
                     </p>
                </div>
                <div className={classes.Skill}>
                    <img src={mobileLogo} alt='mobile' />
                    <h2>Mobile</h2>
                    <p>
                        Android, Ionic, Flutter
                     </p>
                </div>
                <div className={classes.Skill}>
                    <img src={ciLogo} alt='ci' />
                    <h2>CI</h2>
                    <p>
                        Jenkins, Harness, AzureDevOps, Github Actions 
                     </p>
                </div>
                <div className={classes.Skill}>
                    <img src={consoleLogo} alt='console' />
                    <h2>Console</h2>
                    <p>
                        Bash, Batch, PowerShell, GIT
                     </p>
                </div>
                <div className={classes.Skill}>
                    <img src={osLogo} alt='os' />
                    <h2>OS</h2>
                    <p>
                        Windows, Linux, Android
                     </p>
                </div>
                <div className={classes.Skill}>
                    <img src={sqlLogo} alt='sql' />
                    <h2>SQL</h2>
                    <p>
                        SQL Server, Oracle, MySql, SQLite
                     </p>
                </div>                
                <div className={classes.Skill}>
                    <img src={nosqlLogo} alt='nosql' />
                    <h2>NoSQL</h2>
                    <p>
                        MongoDB, Firebase, CosmosDB, DynamoDB
                     </p>
                </div>
                <div className={classes.Skill}>
                    <img src={automatizationLogo} alt='automatization' />
                    <h2>Automatization</h2>
                    <p>
                        Selenium, Serenity BDD, Cypress, Playwright
                     </p>
                </div>
                <div className={classes.Skill}>
                    <img src={architectureLogo} alt='architecture' />
                    <h2>Architecture</h2>
                    <p>
                        UML, Services, Microservices, Pattern
                     </p>
                </div>
                <div className={classes.Skill}>
                    <img src={methodologiesLogo} alt='methodologies' />
                    <h2>
                        Methodologies
                    </h2>
                    <p>
                        Agile, SCRUM, Kanban, Lean
                     </p>
                </div>
                <div className={classes.Skill}>
                    <img src={leadershipLogo} alt='leadership' />
                    <h2>
                        Leadership
                    </h2>
                    <p>
                        Team Building, Code Review, Pair Programming
                     </p>
                </div>
                <div className={classes.Skill}>
                    <img src={infrastructureLogo} alt='infrastructure' />
                    <h2>
                        Infrastructure
                    </h2>
                    <p>
                        Networks, Domains, Active Directory, VM
                     </p>
                </div>
                <div className={classes.Skill}>
                    <img src={cloudLogo} alt='cloud' />
                    <h2>
                        Cloud
                    </h2>
                    <p>
                        Microsoft Azure, AWS, GCP
                     </p>
                </div>
            </div>
        </Fragment>
    )
}

export default Home;
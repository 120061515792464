import React, { Suspense } from 'react'
import Home from './components/Pages/Home/Home';
import Layout from './components/Layout/Layout';
import { Redirect, Route, Switch } from 'react-router-dom';
import Spinner from './components/UI/Spinner/Spinner';

const About = React.lazy(() => import('./components/Pages/About/About'));
const Portfolio = React.lazy(() => import('./components/Pages/Porfolio/Portfolio'));

const App = () => {
  return (
    <Layout>
      <Switch>        
        <Route path="/portfolio" render={() => <Suspense fallback={<Spinner/>}><Portfolio /></Suspense>} />
        <Route path="/about" render={() => <Suspense fallback={<Spinner/>}><About /></Suspense>} />
        <Route exact path="/home" component={Home} />
        <Redirect to="/home" />
      </Switch>
    </Layout>
  );
}

export default App;

import React from 'react'
import { NavLink } from 'react-router-dom'
import classes from './NavigationItem.module.css'

const NavigationItem = props => (
    <li className={classes.NavigationItem}>
        <NavLink        
            activeClassName={props.parent ? classes.ActiveParent : classes.Active}
            to={props.path}>
            {props.children}
        </NavLink>
    </li>
);

export default NavigationItem
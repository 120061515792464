import React from 'react';
import NavigationItems from '../NavigationItems/NavigationItems';
import DrawerToggle from '../SideDrawer/DrawerToggle/DrawerToggle';
import NavigationItem from '../NavigationItems/NavigationItem/NavigationItem'
import classes from './Toolbar.module.css';
import { withRouter, matchPath } from 'react-router';

const Toolbar = props => {
    return (
        <header className={classes.Toolbar}>
            <DrawerToggle click={props.click} />
            <div />
            <div className={classes.ToolbarDivNav}>
                <nav className={classes.DesktopOnly}>
                    <NavigationItems>
                        <NavigationItem path='/home'>
                            Home
                        </NavigationItem>
                        <NavigationItem path='/about'>
                            About
                        </NavigationItem>
                        <NavigationItem path='/portfolio' parent>
                            Portfolio
                        </NavigationItem>
                    </NavigationItems>
                </nav>
                {matchPath(props.location.pathname, '/portfolio') ?
                    <nav className={classes.DesktopOnly}>
                        <NavigationItems>
                            <NavigationItem path='/portfolio/intro'>
                                Introduction
                        </NavigationItem>
                            <NavigationItem path='/portfolio/ck'>
                                C.Knowledge
                        </NavigationItem>
                            <NavigationItem path='/portfolio/backend'>
                                Backend
                        </NavigationItem>
                            <NavigationItem path='/portfolio/frontend'>
                                Frontend
                        </NavigationItem>
                        </NavigationItems>
                    </nav> : null}
            </div>
        </header>
    )
}

export default withRouter(Toolbar);
import React, { Fragment, useState } from 'react';
import Footer from '../Navigation/Footer/Footer';
import SideDrawer from '../Navigation/SideDrawer/SideDrawer';
import Toolbar from '../Navigation/Toolbar/Toolbar';
import classes from './Layout.module.css';

const Layout = props => {
    const [ShowSideDrawer, setShowSideDrawer] = useState(false)

    return (
        <Fragment>
            <Toolbar click={() => setShowSideDrawer(true)}/>
            <SideDrawer
                show={ShowSideDrawer}
                close={() => setShowSideDrawer(false)} />
            <main className={classes.Main}>
                {props.children}
            </main>            
            <Footer />
        </Fragment>
    )
}

export default Layout;